import { Button, type ButtonProps, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import { ChevronDownIcon } from '@tyro/icons';
import type React from 'react';
import { useRef } from 'react';
import { useDisclosure } from '../../hooks';
import { ContextMenu, type ContextMenuProps } from './context-menu';

export interface ActionMenuProps {
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  buttonProps?: ButtonProps;
  menuProps?: ContextMenuProps['menuProps'];
  menuItems: ContextMenuProps['menuItems'];
  iconOnly?: boolean;
}

export function ActionMenu({
  buttonLabel,
  buttonIcon,
  buttonProps,
  menuProps,
  menuItems,
  iconOnly = false,
}: ActionMenuProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [t] = useTranslation(['common']);
  const { id, isOpen, onClose, getButtonProps } = useDisclosure();

  return (
    <>
      {iconOnly ? (
        <Tooltip title={buttonLabel ?? t('common:actions.title')}>
          <IconButton
            ref={buttonRef}
            aria-haspopup="true"
            variant="soft"
            aria-label={buttonLabel ?? t('common:actions.title')}
            {...getButtonProps()}
            {...buttonProps}
          >
            {buttonIcon ?? <ChevronDownIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          ref={buttonRef}
          aria-haspopup="true"
          variant="soft"
          {...getButtonProps()}
          endIcon={buttonIcon ?? <ChevronDownIcon />}
          {...buttonProps}
        >
          {buttonLabel ?? t('common:actions.title')}
        </Button>
      )}
      <ContextMenu
        buttonRef={buttonRef}
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          ...menuProps,
          sx: {
            mt: 1,
            ml: 0,
            ...menuProps?.sx,
          },
        }}
        menuItems={menuItems}
      />
    </>
  );
}
