import {
  Box,
  CircularProgress,
  Stack,
  type StackProps,
  type SxProps,
  type Theme,
  alpha,
} from '@mui/material';
import {
  type ComponentProps,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Outlet, useMatches } from 'react-router-dom';

import { type PermissionUtils, usePermissions } from '@tyro/api';
import { LazyLoader } from '../lazy-loader';

import { LinkTab, Tabs } from '../tabs';

type TabLink = {
  label: string;
  value: string;
  hasAccess?: (permissions: PermissionUtils) => boolean;
  icon?: ComponentProps<typeof LinkTab>['icon'];
};

type TabNavigationProps = PropsWithChildren<{
  links: TabLink[];
  TabProps?: Omit<
    ComponentProps<typeof Tabs>,
    'value' | 'onChange' | 'variant'
  >;
  ContainerProps?: StackProps;
  variant?: ComponentProps<typeof Tabs>['variant'] | 'white-filled';
}>;

export function getMatchedPageContainerTabValue(
  lastUrl: string,
  tabs: Pick<TabLink, 'value'>[],
  returnDefault?: true,
): string;

export function getMatchedPageContainerTabValue(
  lastUrl: string,
  tabs: Pick<TabLink, 'value'>[],
  returnDefault?: false,
): string | null;

export function getMatchedPageContainerTabValue(
  lastUrl: string,
  tabs: Pick<TabLink, 'value'>[],
  returnDefault = true,
) {
  const defaultValue = returnDefault ? tabs[0].value : null;
  let matchedPathname = tabs.find(({ value }) => lastUrl.endsWith(value));

  if (!matchedPathname) {
    matchedPathname = tabs.find(({ value }) => lastUrl.includes(value));
  }

  return matchedPathname?.value ?? defaultValue;
}

const getWhiteFilledStyling: SxProps<Theme> = (theme: Theme) => ({
  '&': {
    overflow: 'visible',
  },
  '& .MuiTabs-scroller': {
    backgroundColor: 'white',
    width: 'auto',
    flex: '0 1 auto',
    p: 1.5,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'indigo.50',
    boxShadow: `0 1px 6px 0 ${alpha(theme.palette.indigo[500], 0.1)}`,
  },
  '& .MuiTab-root': {
    minHeight: 32,
    zIndex: 2,
    px: 1,
    fontWeight: 500,

    '&:not(.Mui-selected)': {
      color: 'text.primary',
    },

    '&.Mui-selected': {
      color: 'primary.main',
    },

    '&:not(:last-of-type)': {
      mr: 3.25,
    },

    '& svg': {
      width: 18,
      height: 18,
    },
  },
  '& .MuiTabs-indicator': {
    height: 32,
    bottom: 12,
    backgroundColor: 'indigo.50',
    borderRadius: 1,
  },
});

export const TabPageContainer = ({
  links,
  variant,
  TabProps,
  ContainerProps,
}: TabNavigationProps) => {
  const matches = useMatches();
  const permissions = usePermissions();
  const isWhiteFilled = variant === 'white-filled';

  const lastUrl = matches[matches.length - 1].pathname;

  const [value, setValue] = useState<string>(() =>
    getMatchedPageContainerTabValue(lastUrl, links),
  );

  useEffect(() => {
    const matchedPath = getMatchedPageContainerTabValue(lastUrl, links);
    if (value !== matchedPath) {
      setValue(matchedPath);
    }
  }, [lastUrl]);

  return (
    <Stack flexDirection="column" gap={3} flex={1} {...ContainerProps}>
      <Tabs
        value={value}
        variant={isWhiteFilled ? undefined : variant}
        {...TabProps}
        sx={(theme) => ({
          ...(isWhiteFilled ? getWhiteFilledStyling(theme) : {}),
          ...TabProps?.sx,
        })}
      >
        {links
          .filter((tab) =>
            tab.hasAccess != null ? tab.hasAccess(permissions) : true,
          )
          .map(({ label, value: tabValue, icon }) => (
            <LinkTab
              key={tabValue}
              label={label}
              value={tabValue}
              to={`./${tabValue}`}
              icon={icon}
            />
          ))}
      </Tabs>
      <LazyLoader
        fallback={
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <Outlet key={lastUrl} />
      </LazyLoader>
    </Stack>
  );
};

if (process.env.NODE_ENV !== 'production') {
  TabPageContainer.displayName = 'TabPageContainer';
}
