import {
  type ColDef,
  type ColumnRowGroupChangedEvent,
  ModuleRegistry,
} from '@ag-grid-community/core';
import {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact, type AgGridReactProps } from '@ag-grid-community/react';

import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import '@ag-grid-community/styles/ag-grid.css';
import {
  Box,
  type BoxProps,
  type CardProps,
  IconButton,
  Stack,
} from '@mui/material';

import './styles.css';
import { CollapseIcon, ExpandIcon } from '@tyro/icons';
import { useMeasure } from 'react-use';
import { useDisclosure } from '../../../hooks/use-disclosure';
import { useMergeRefs } from '../../../hooks/use-merge-refs';
import { normaliseForeignCharacters } from '../../../utils/normalise-foreign-characters';
import { ExpandableCard } from '../../expandable-card';
import { SearchInput } from '../../search-input';
import {
  type ReturnTypeUseEditableState,
  type UseEditableStateProps,
  useEditableState,
} from '../hooks/use-editable-state';
import { BulkEditSaveBar } from './bulk-edit-save-bar';
import { TableLoadingOverlay } from './loading-overlay';

if (process.env.NODE_ENV === 'development') {
  // Monkey patching errors in dev to stop the console from being spammed with license errors
  const consoleError = console.error;
  const errorsToFilterOut = [
    '****************************************************************************************************************',
    'If you want to hide the watermark, please email info@ag-grid.com for a trial license',
    'This is an evaluation only version, it is not licensed for development projects intended for production',
    'All AG Grid Enterprise features are unlocked',
    '****************************************** License Key Not Found ***********************************************',
    '***************************************** AG Grid Enterprise License *******************************************',
  ];

  console.error = function filteredErrors(...original) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [message] = original;
    if (
      typeof message !== 'string' ||
      !errorsToFilterOut.some((error) => message.includes(error))
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      consoleError(...original);
    }
  };
}

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  ExcelExportModule,
  RangeSelectionModule,
  MenuModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  StatusBarModule,
]);

export type {
  GridOptions,
  ICellRendererParams,
  CellValueChangedEvent,
  ICellEditorParams,
  ValueGetterParams,
  ValueFormatterParams,
  NewValueParams,
  ProcessCellForExportParams,
  ValueGetterFunc,
  CellClassParams,
  HeaderClassParams,
  IRowNode,
} from '@ag-grid-community/core';
export type {
  ReturnTypeUseEditableState as ReturnTypeTableUseEditableState,
  ValueSetterParams,
} from '../hooks/use-editable-state';

export type { AgGridReact } from '@ag-grid-community/react';

if (process.env.AG_GRID_KEY) {
  LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);
}

export interface TableProps<T> extends AgGridReactProps<T> {
  tableId?: string | number;
  rowData: T[];
  getRowId: AgGridReactProps<T>['getRowId'];
  onBulkSave?: UseEditableStateProps<T>['onBulkSave'];
  onBulkSaveCanceled?: UseEditableStateProps<T>['onBulkSaveCanceled'];
  onRowSelection?: (selectedRows: T[]) => void;
  sx?: CardProps['sx'];
  tableContainerSx?: BoxProps['sx'];
  rightAdornment?: React.ReactNode;
  toolbar?: React.ReactNode;
  editingStateRef?: React.Ref<ReturnTypeUseEditableState<T>>;
  isLoading?: boolean;
  additionalEditBarElements?: React.ReactNode;
  externalSearchValue?: string;
  visibleDataRef?: React.Ref<() => T[]>;
  visibleRows?: number;
  autoSave?: boolean;
}

const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  cellStyle: {
    alignItems: 'center',
  },
  useValueFormatterForExport: true,
  cellClass: (params) => {
    if (params.colDef.editable) {
      return 'ag-editable-cell';
    }
  },
  cellRendererSelector: ({ node }) => {
    if (node.group) {
      return { component: 'agGroupCellRenderer' };
    }
    return undefined;
  },
};

const quickFilterMatcher = (
  quickFilterParts: string[],
  rowQuickFilterAggregateText: string,
) => {
  const normalisedRow = normaliseForeignCharacters(rowQuickFilterAggregateText);

  const regex = new RegExp(
    quickFilterParts
      .map((value) => value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|'),
    'i',
  );

  return regex.test(normalisedRow);
};

const TOOLBAR_HEIGHT = 72;
const MIN_TABLE_HEIGHT = 460;

function TableInner<T extends object>(
  {
    onFirstDataRendered,
    onBulkSave,
    onBulkSaveCanceled,
    tableContainerSx,
    sx,
    onRowSelection,
    rightAdornment,
    autoGroupColumnDef,
    rowHeight = 56,
    rowSelection,
    onColumnEverythingChanged,
    toolbar,
    editingStateRef,
    isLoading,
    additionalEditBarElements,
    visibleDataRef,
    tableId,
    externalSearchValue = '',
    visibleRows,
    autoSave,
    ...props
  }: TableProps<T>,
  ref: React.Ref<AgGridReact<T>>,
) {
  const [searchValue, setSearchValue] = useState('');
  const tableRef = useRef<AgGridReact<T>>();
  const refs = useMergeRefs(tableRef, ref);
  const [tableContainerRef, { height: tableContainerHeight }] = useMeasure();
  const {
    isOpen: isExpanded,
    onToggle: onToggleExpansion,
    onClose: onCollapseExpansion,
  } = useDisclosure();

  const heightBasedOnRows = (props.rowData.length + 1) * rowHeight;
  const minTableHeight = visibleRows
    ? visibleRows * rowHeight
    : MIN_TABLE_HEIGHT;

  const innerContainerHeight = Math.max(
    Math.min(heightBasedOnRows, tableContainerHeight),
    minTableHeight,
  );

  const [tableHeight, setTableHeight] = useState(innerContainerHeight);

  const editingUtils = useEditableState<T>({
    autoSave,
    tableId,
    tableRef,
    onBulkSave,
    onBulkSaveCanceled,
  });
  const {
    isEditing,
    editingState,
    numberOfEdits,
    onSave,
    onCancel,
    onCellValueChanged,
    applyUpdatesToTable,
  } = editingUtils;

  useImperativeHandle(editingStateRef, () => editingUtils, [editingUtils]);
  useImperativeHandle(
    visibleDataRef,
    () => () => {
      const listData: Array<T> = [];
      tableRef?.current?.api?.forEachNodeAfterFilterAndSort?.((node) => {
        if (node.data) {
          listData.push(node.data);
        }
      });

      return listData;
    },
    [],
  );

  const colDefs = useMemo<ColDef<T>>(
    () => ({
      ...(defaultColDef as ColDef<T>),
      ...props.defaultColDef,
    }),
    [props.defaultColDef],
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = tableRef?.current?.api?.getSelectedRows();
    if (onRowSelection && selectedRows) {
      onRowSelection(selectedRows);
    }
  }, []);

  const defaultAutoGroupColumnDef =
    rowSelection === 'multiple'
      ? {
          cellRendererParams: {
            checkbox: true,
          },
        }
      : undefined;

  const onColumnRowGroupChanged = useCallback(
    ({ column, api }: ColumnRowGroupChangedEvent) => {
      if (column) {
        const colDef = column.getColDef();
        const sort = column.getSort();
        const colId = column.getColId();

        if (colDef.sortable && sort === undefined) {
          api.applyColumnState({
            state: [{ colId, sort: 'asc' }],
            defaultState: { sort: null },
          });
        }
      }
    },
    [],
  );

  const calculateTableHeight = useCallback(() => {
    const hasAutoHeight = (props.columnDefs || []).some(
      (columnDef: ColDef<T>) => columnDef.autoHeight,
    );

    if (hasAutoHeight) {
      const nodes = tableRef.current?.api.getRenderedNodes() || [];
      const rowIds = nodes.flatMap((row) => (row.id ? [row.id] : []));

      const currentTableHeight = rowIds.reduce((height, rowId) => {
        const currentRow = document.querySelector(`[row-id="${rowId}"]`);
        const { clientHeight = 0 } = currentRow || {};

        return height + clientHeight;
      }, TOOLBAR_HEIGHT);

      setTableHeight(currentTableHeight);
    }
  }, [props.columnDefs]);

  useEffect(() => {
    if (externalSearchValue !== searchValue) {
      setSearchValue(externalSearchValue);
    }
  }, [externalSearchValue]);

  useEffect(() => {
    return () => {
      for (const popup of document.querySelectorAll('.ag-popup')) {
        popup.remove();
      }
    };
  }, []);

  const maxHeight = Math.max(minTableHeight, heightBasedOnRows, tableHeight);

  return (
    <>
      <ExpandableCard
        isExpanded={isExpanded}
        onCollapse={onCollapseExpansion}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '&:not(.expanded)': {
            minHeight: minTableHeight + TOOLBAR_HEIGHT,
            maxHeight: maxHeight + TOOLBAR_HEIGHT,
          },
          ...sx,
        }}
      >
        {toolbar || (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            p={2}
          >
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              {rightAdornment}
              <IconButton onClick={onToggleExpansion}>
                {isExpanded ? (
                  <CollapseIcon sx={{ color: 'primary.main' }} />
                ) : (
                  <ExpandIcon sx={{ color: 'primary.main' }} />
                )}
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Box
          ref={tableContainerRef}
          className="ag-theme-tyro"
          sx={{
            ...tableContainerSx,
          }}
        >
          <Box
            sx={{
              height: Math.max(innerContainerHeight, tableHeight),
              flex: 1,
            }}
          >
            {isLoading ? (
              <TableLoadingOverlay />
            ) : (
              <AgGridReact<(typeof props.rowData)[number]>
                ref={refs}
                quickFilterText={searchValue}
                undoRedoCellEditing
                undoRedoCellEditingLimit={20}
                popupParent={document.body}
                suppressRowClickSelection
                enableRangeSelection
                enableFillHandle
                fillHandleDirection="y"
                allowContextMenuWithControlKey
                onSelectionChanged={onSelectionChanged}
                rowHeight={rowHeight}
                rowSelection={rowSelection}
                autoGroupColumnDef={
                  autoGroupColumnDef || defaultAutoGroupColumnDef
                }
                groupSelectsChildren={rowSelection === 'multiple'}
                groupSelectsFiltered={rowSelection === 'multiple'}
                stopEditingWhenCellsLoseFocus
                processCellForClipboard={({ value }) => {
                  if (value === null || value === undefined) {
                    return '';
                  }

                  if (typeof value === 'object') {
                    return JSON.stringify(value);
                  }

                  return value as unknown;
                }}
                {...props}
                quickFilterMatcher={quickFilterMatcher}
                defaultColDef={colDefs}
                onCellValueChanged={(args) => {
                  onCellValueChanged(args);
                  props.onCellValueChanged?.(args);
                }}
                onFirstDataRendered={(params) => {
                  const { api } = params;
                  api.autoSizeAllColumns(false);
                  const columnWidths = props.columnDefs
                    ?.filter(
                      (column: ColDef<T>) =>
                        column?.width && (column?.field || column?.colId),
                    )
                    ?.map((column: ColDef<T>) => ({
                      key: (column.field || column.colId) as string,
                      newWidth: column.width as number,
                    }));

                  if (columnWidths) {
                    api.setColumnWidths(columnWidths);
                  }

                  applyUpdatesToTable('newValue');

                  onFirstDataRendered?.(params);
                  calculateTableHeight();
                }}
                onModelUpdated={(params) => {
                  props.onModelUpdated?.(params);
                  calculateTableHeight();
                }}
                onColumnEverythingChanged={(params) => {
                  applyUpdatesToTable('newValue');
                  onColumnEverythingChanged?.(params);
                }}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
              />
            )}
          </Box>
        </Box>
      </ExpandableCard>
      <BulkEditSaveBar
        isEditing={isEditing}
        editingState={editingState}
        numberOfEdits={numberOfEdits}
        onSave={onSave}
        onCancel={onCancel}
        additionalEditBarElements={additionalEditBarElements}
      />
    </>
  );
}

export const Table = forwardRef(TableInner) as <T>(
  props: TableProps<T> & { ref?: ForwardedRef<AgGridReact<T>> | null },
) => ReturnType<typeof TableInner>;
