import {
  ToggleButton,
  ToggleButtonGroup,
  type ToggleButtonGroupProps,
  type ToggleButtonProps,
} from '@mui/material';
import type { Colour } from '@tyro/api';
import { useEffect, useRef, useState } from 'react';

export type RadioTabOption = {
  value: string;
  label: string;
  color?: Colour | 'indigo';
};

export interface RadioTabGroupProps<
  Option extends object | RadioTabOption,
  Key extends keyof Option,
> extends Omit<ToggleButtonGroupProps, 'onChange'> {
  value: Option[Key] | null;
  onChange: (value: Option[Key]) => void;
  options: Option[];
  optionIdKey?: Key;
  optionTextKey?: keyof Option;
}

export const RadioTabGroup = <
  Option extends object | RadioTabOption,
  Key extends keyof Option,
>({
  value,
  onChange,
  options,
  optionIdKey = 'value' as Key,
  optionTextKey = 'label' as keyof Option,
  ...props
}: RadioTabGroupProps<Option, Key>) => {
  const groupRef = useRef<HTMLDivElement>(null);
  const [focusedTab, setFocusedTab] = useState<number>(0);

  useEffect(() => {
    if (focusedTab >= 0 && focusedTab < options.length) {
      groupRef.current?.querySelectorAll('button')[focusedTab]?.focus();
    }
  }, [focusedTab]);

  return (
    <ToggleButtonGroup
      ref={groupRef}
      size="small"
      exclusive
      value={value}
      role="radiogroup"
      sx={{
        '& .MuiToggleButtonGroup-grouped': {
          margin: {
            xs: 0.25,
            sm: 0.5,
          },
          paddingX: {
            xs: 0.5,
            sm: 1.25,
          },
        },
      }}
      onChange={(_ev, code: Option[Key]) => {
        onChange(code);
      }}
      {...props}
    >
      {options.map((option, index) => {
        const color = (option as RadioTabOption)?.color ?? 'indigo';

        const sharedProps = {
          value: option[optionIdKey],
          label: option[optionTextKey] as string,
        };
        const isFocusedTab = focusedTab === index;

        return (
          <ToggleButton
            key={sharedProps.value as string}
            value={sharedProps.value as string}
            color={color as ToggleButtonProps['color']}
            role="radio"
            // biome-ignore lint/a11y/useValidAriaValues: <explanation>
            aria-pressed={undefined}
            type={undefined}
            aria-checked={value === sharedProps.value}
            tabIndex={isFocusedTab ? 0 : -1}
            onKeyDown={(event) => {
              if (['ArrowRight', 'ArrowDown'].includes(event.key)) {
                event.preventDefault();
                setFocusedTab((previousIndex) => {
                  const newIndex = previousIndex + 1;
                  return newIndex === options.length ? 0 : newIndex;
                });
              } else if (['ArrowLeft', 'ArrowUp'].includes(event.key)) {
                event.preventDefault();
                setFocusedTab((previousIndex) => {
                  const newIndex = previousIndex - 1;
                  return newIndex < 0 ? options.length - 1 : newIndex;
                });
              }
            }}
          >
            {sharedProps.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
