import {
  type AuthenticationResult,
  type EventMessage,
  EventType,
} from '@azure/msal-browser';
import {
  MsalProvider,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { resetDefaultProfile } from '../api/user-logout';
import { clearUsersData } from '../utils';
import { loginRequest, msalInstance } from '../utils/msal-configs';

export type AuthContextValue = {
  isAuthenticated: boolean;
  isTokenInitialized: boolean;
  logout: () => void;
  login: () => void;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

function InnerAuthProvider({ children }: AuthProviderProps) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const [isTokenInitialized, setIsTokenInitialized] = useState(false);

  const logout = useCallback(() => {
    resetDefaultProfile();
    instance.logoutRedirect({
      account,
    });
  }, [instance, account]);

  const login = useCallback(() => {
    instance.loginRedirect(loginRequest);
  }, [instance]);

  useEffect(() => {
    if (inProgress === 'none') {
      if (!isAuthenticated) {
        clearUsersData();
        const { pathname, search } = window.location;
        instance.loginRedirect({
          ...loginRequest,
          state: `${pathname}${search}`,
        });
      } else {
        msalInstance.setActiveAccount(account);
        setIsTokenInitialized(true);
      }
    }
  }, [account, instance, inProgress, isAuthenticated]);

  useEffect(() => {
    instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;

        if (payload.state) {
          window.localStorage.setItem('tyro:initial-route', payload.state);
        }
      }

      if (
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
        event.error?.name === 'InteractionRequiredAuthError'
      ) {
        logout();
      }
    });
  }, [instance]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      isTokenInitialized,
      login,
      logout,
    }),
    [isAuthenticated, isTokenInitialized, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function AuthProvider({ children }: AuthProviderProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <InnerAuthProvider>{children}</InnerAuthProvider>
    </MsalProvider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext');
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
